import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./Header.scss";

const Header = props => {
  const { favouriteList } = props;
  return (
    <header>
      <div className="container">
        <div className="logo">
          Galler<b>easy</b>
        </div>
        <div className="navigation">
          <ul>
            <li>
              <NavLink exact to="/">
                Search
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/favourite">
                Favourites{" "}
                {favouriteList.length > 0 && (
                  <span>({favouriteList.length})</span>
                )}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default connect(state => ({ ...state.app }))(Header);
