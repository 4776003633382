import React, { Component } from "react";
import { connect } from "react-redux";
import nProgress from "nprogress";

import { fetchFavourites, toggleFavourite } from "../../modules/app";
import heartIcon from "../../assets/heart.svg";

class Favourite extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoaded: false };
  }
  async componentDidMount() {
    const { doFetchFavourite } = this.props;
    nProgress.start();
    await doFetchFavourite();
    nProgress.done();
    this.setState({ isLoaded: true });
  }

  renderImgList = () => {
    const { isLoaded } = this.state;
    const { imageFavouriteList, favouriteList, doToggleFavourite } = this.props;

    if (isLoaded && imageFavouriteList && imageFavouriteList.length === 0)
      return (
        <div className="text-center">
          There's nothing in your favourite list.
        </div>
      );

    return (
      <ul>
        {imageFavouriteList.map(img => {
          return (
            <li
              key={img.id}
              style={{
                background: `url(${img.images.original.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            >
              {/* <img src={img.images.fixed_width.url} alt={img.title} /> */}
              <div
                className={`favourite ${
                  favouriteList.indexOf(img.id) >= 0 ? "favourite--active" : ""
                }`}
                onClick={() => doToggleFavourite(img.id, true)}
              >
                <img src={heartIcon} alt="favourite Heart Icon" />
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  render() {
    return (
      <main>
        <div className="container">
          <div className="imgGrid">{this.renderImgList()}</div>
        </div>
      </main>
    );
  }
}

export default connect(state => ({ ...state.app }), {
  doFetchFavourite: fetchFavourites,
  doToggleFavourite: toggleFavourite
})(Favourite);
