import api from "../api/giphy";

//Action Variable
const FETCH_IMAGES = "FETCH_IMAGES";
const FETCH_FAVOURITE = "FETCH_FAVOURITE";
const SET_FAVOURITE = "SET_FAVOURITE";
const SET_FETCHABLE = "SET_FETCHABLE";
const UNLOAD_IMAGES = "UNLOAD_IMAGES";
const UNLOAD_FAVOURITE = "UNLOAD_FAVOURITE";
const NOT_FOUND = "NOT_FOUND";

const initState = {
  imageList: [],
  imageFavouriteList: [],
  fetchable: false,
  favouriteList: JSON.parse(localStorage.getItem("favouriteList")) || [],
  isNotFound: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case FETCH_IMAGES:
      const imageList = [...state.imageList, ...action.payload];
      return { ...state, imageList };
    case FETCH_FAVOURITE:
      return { ...state, imageFavouriteList: action.payload };
    case SET_FAVOURITE:
      return { ...state, favouriteList: action.payload };
    case SET_FETCHABLE:
      return { ...state, fetchable: action.payload };
    case NOT_FOUND:
      return { ...state, isNotFound: action.payload };
    case UNLOAD_IMAGES:
      return { ...state, imageList: [], fetchable: false };
    case UNLOAD_FAVOURITE:
      return { ...state, imageFavouriteList: [] };
    default:
      return state;
  }
};

//Action Creators
export const unloadImages = () => async dispatch => {
  await dispatch({ type: NOT_FOUND, payload: false });
  await dispatch({ type: UNLOAD_IMAGES });
};

export const fetchImages = (q, offset = 0, limit = 8) => async dispatch => {
  try {
    const response = await api.get("/search", {
      params: {
        q,
        offset,
        limit
      }
    });

    const { count, total_count } = response.data.pagination;
    const { data } = response.data;
    if (count === 0) return dispatch({ type: NOT_FOUND, payload: true });
    dispatch({
      type: SET_FETCHABLE,
      payload: offset + limit > total_count ? false : true
    });
    dispatch({ type: FETCH_IMAGES, payload: data });
  } catch (e) {
    alert("There's something happend, please try again!");
  }
};

export const fetchFavourites = (forceReload = true) => async (
  dispatch,
  getState
) => {
  const { favouriteList } = getState().app;
  const ids = favouriteList.join(",");
  dispatch(unloadImages());
  if (forceReload || favouriteList.length === 0)
    await dispatch({ type: UNLOAD_FAVOURITE });

  if (favouriteList.length === 0) return;

  try {
    const response = await api.get(null, {
      params: {
        ids
      }
    });
    const { data } = response.data;
    dispatch({ type: FETCH_FAVOURITE, payload: data });
  } catch (e) {
    alert("There's something happend, please try again!");
  }
};

export const toggleFavourite = (imgID, forceReload = false) => (
  dispatch,
  getState
) => {
  const { favouriteList } = getState().app;

  const newFavouriteList =
    favouriteList.indexOf(imgID) >= 0
      ? favouriteList.filter(id => id !== imgID)
      : [...favouriteList, imgID];

  //Set Local Store
  localStorage.setItem("favouriteList", JSON.stringify(newFavouriteList));

  dispatch({ type: SET_FAVOURITE, payload: newFavouriteList });

  if (forceReload) dispatch(fetchFavourites(false));
};
