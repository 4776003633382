import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.giphy.com/v1/gifs",
  timeout: 120000
});

instance.interceptors.request.use(function(config) {
  const { params } = config;
  const limit = params.limit || 8;
  const offset = params.offset || 0;
  config.params = {
    ...config.params,
    api_key: "dy1i2J4FClQWmRB22DlhQ9BxY3qI0Crr",
    limit,
    offset
  };

  return config;
});

export default instance;
