// import { lazy } from "react";
// const Favourite = lazy(() => import("./views/Favourite/Favourite"));
import Home from "./views/Home/Home";
import Favourite from "./views/Favourite/Favourite";

const routes = [
  { path: "/favourite", component: Favourite },
  { path: "/", component: Home }
];

export default routes;
