import React, { Suspense } from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import routes from "../../routes";

const App = () => {
  const renderRoutes = () => {
    return routes.map(({ path, component }) => (
      <Route path={path} component={component} key={path} exact />
    ));
  };

  return (
    <>
      <BrowserRouter>
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>{renderRoutes()}</Switch>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
