import React, { Component } from "react";
import { connect } from "react-redux";
import nprogress from "nprogress";
import { fetchImages, toggleFavourite, unloadImages } from "../../modules/app";
//Styles import
import "./Home.scss";
import heartIcon from "../../assets/heart.svg";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: "",
      savedKeyword: "",
      page: 1,
      loading: false
    };
  }
  handleInputChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleSubmitSearch = async e => {
    e.preventDefault();
    const { keyword } = this.state;
    const { doFetchImages, doUnloadImages } = this.props;
    if (!keyword) return alert("Please enter keyword");
    //Reset all state before call api
    this.setState({ page: 1, savedKeyword: keyword }); //reset page
    await doUnloadImages(); //reset image before submit

    nprogress.start();
    await doFetchImages(keyword);
    nprogress.done();
  };

  handleAddFavourite = imgID => {
    const { favouriteList } = this.state;

    if (favouriteList.indexOf(imgID) >= 0)
      return this.setState({
        favouriteList: favouriteList.filter(id => id !== imgID)
      });

    this.setState({ favouriteList: [...favouriteList, imgID] });
  };

  handleLoadMore = async () => {
    const { page, savedKeyword } = this.state;
    const { doFetchImages } = this.props;

    nprogress.start();
    this.setState({ loading: true });
    await doFetchImages(savedKeyword, page * 8 + 1);
    this.setState({ page: page + 1 });
    this.setState({ loading: false });
    nprogress.done();
  };

  renderImgList = () => {
    const {
      isNotFound,
      imageList,
      favouriteList,
      doToggleFavourite
    } = this.props;

    if (!isNotFound && imageList.length === 0) return null;
    if (isNotFound)
      return (
        <div className="text-center">
          We can not find any result with your keywords
        </div>
      );

    return (
      <ul>
        {imageList.map(img => {
          return (
            <li
              key={img.id}
              style={{
                background: `url(${img.images.original.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            >
              {/* <img src={img.images.fixed_width.url} alt={img.title} /> */}
              <div
                className={`favourite ${
                  favouriteList.indexOf(img.id) >= 0 ? "favourite--active" : ""
                }`}
                onClick={() => doToggleFavourite(img.id)}
              >
                <img src={heartIcon} alt="favourite Heart Icon" />
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  renderFetchMoreButton = () => {
    const { isNotFound, imageList, fetchable } = this.props;
    const { loading } = this.state;
    if (isNotFound || (!isNotFound && imageList.length === 0)) return null;
    if (!fetchable) return <div>You've reach the last result</div>;
    return (
      <button
        className="btn btn--fetchMore"
        onClick={this.handleLoadMore}
        disabled={loading}
      >
        {loading ? "Fetching....." : "Fetch More"}
      </button>
    );
  };

  render() {
    const { keyword } = this.state;

    return (
      <>
        <main>
          <div className="container">
            <form
              action="#"
              className="searchForm text-center"
              onSubmit={e => this.handleSubmitSearch(e)}
            >
              <input
                type="text"
                placeholder="Start searching for images!"
                value={keyword}
                name="keyword"
                onChange={e => this.handleInputChange(e)}
              />
            </form>
            <div className="imgGrid">{this.renderImgList()}</div>
            <div className="text-center">{this.renderFetchMoreButton()}</div>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = state => ({ ...state.app });

export default connect(mapStateToProps, {
  doFetchImages: fetchImages,
  doToggleFavourite: toggleFavourite,
  doUnloadImages: unloadImages
})(Home);
